<template>
  <div id="mine">
    <div class="info">
      <div class="info-left">
        <div class="name van-ellipsis">Hi~,{{ info.nickname }}</div>
        <img
          v-if="info.is_vip"
          class="vip"
          src="./../../assets/img/02.png"
          alt=""
        />
      </div>
      <div class="info-right">
        <img class="avatar" :src="info.headimgurl" alt="" />
        <img
          v-if="info.sex == 1"
          class="logo"
          src="../../assets/img/mlogo.png"
          alt=""
        />
        <img v-else class="logo" src="../../assets/img/wlogo.png" alt="" />
      </div>
    </div>
    <div class="vip-info" v-if="info.is_vip">
      <i class="icon-vip"></i>
      <div class="center">会员到期时间:{{ info.vip_end_time }}</div>
      <button class="renewal-btn" @click="vipShow = true">续费</button>
    </div>
    <div class="vip-info" v-if="!info.is_vip">
      <van-notice-bar :scrollable="false">
        <i class="icon-vip" slot="left-icon"></i>
        <van-swipe
          vertical
          class="notice-swipe"
          :autoplay="3000"
          :show-indicators="false"
          color="#895423"
        >
          <van-swipe-item>VIP会员才能选择心仪的对象噢！</van-swipe-item>
          <van-swipe-item>会员享受全场活动价低至八折</van-swipe-item>
        </van-swipe>
      </van-notice-bar>
      <button class="renewal-btn" @click="vipShow = true">开通VIP</button>
      <!-- <i class="icon-vip"></i>
      <div class="center">会员到期时间:{{ info.vip_end_time }}</div>
      <button class="renewal-btn">续费</button> -->
    </div>
    <div class="activity">
      <van-cell
        title-class="title"
        :border="false"
        title="我的活动"
        value="全部活动"
        is-link
        @click="allActiity"
      >
      </van-cell>
      <van-grid :border="false">
        <van-grid-item text="已预约" :badge="yuyuebadge" @click="goactive(1)">
          <template slot="icon">
            <img class="icon-img" src="./../../assets/img/09.png" alt="" />
          </template>
        </van-grid-item>
        <van-grid-item text="待参加" :badge="joinbadge" @click="goactive(2)">
          <template slot="icon">
            <img class="icon-img" src="./../../assets/img/06.png" alt="" />
          </template>
        </van-grid-item>
        <van-grid-item text="已使用" :badge="usedbadge" @click="goactive(3)">
          <template slot="icon">
            <img class="icon-img" src="./../../assets/img/08.png" alt="" />
          </template>
        </van-grid-item>
        <van-grid-item text="退款/售后" :badge="shoubadge" @click="goactive(4)">
          <template slot="icon">
            <img class="icon-img" src="./../../assets/img/07.png" alt="" />
          </template>
        </van-grid-item>
      </van-grid>
      <div class="apply" v-if="joinActivity">
        <div class="active">
          <img class="actimg" :src="joinActivity.image" alt="" />
        </div>
        <div class="axtname">
          <div class="txt van-ellipsis">
            {{ joinActivity.title }}
          </div>
          <div>{{ joinActivity.activity_time }}</div>
        </div>
      </div>
    </div>
    <!-- 我的红娘 -->
    <div class="matchmaker">
      <van-cell title-class="title" :border="false" title="联系红娘" />
      <img class="vx" src="../../assets/img/zpj.png" alt="" />
      <div class="add">长按添加红娘微信</div>
      <!-- :value="myMatchmaker ? '更换红娘' : '全部红娘'"
        is-link
        @click="addMatchmaker" -->
      <!-- <div class="apply" v-if="myMatchmaker">
        <div class="active">
          <img class="actimg" :src="myMatchmaker.headimgurl" alt="" />
        </div>
        <div class="axtname">
          <div class="txt van-ellipsis">
            {{ myMatchmaker.nickname }}
          </div>
          <div>{{ myMatchmaker.description }}</div>
        </div>
      </div>
      <div class="empty" v-else>
        <div class="add" @click="addMatchmaker">
          <img src="../../assets/img/add.png" alt="" />添加红娘
        </div>
        <div class="tip">享受24h专属服务</div>
      </div> -->
    </div>
    <div class="zhanwei"></div>
    <!-- 开通会员弹窗 -->
    <van-popup class="popup" v-model="vipShow" position="bottom" closeable
      ><!-- :style="{ height: '78%' }" -->
      <div class="title">{{ info.is_vip ? "续费" : "开通" }}VIP会员</div>
      <!--  <div class="first">VIP会员才能选择心仪的对象噢！</div>
      <div class="second">
        <span class="pink">心仪的对象</span>参加的活动会提前通知你
      </div> -->
      <img class="openvip" src="../../assets/img/20.png" alt="" />
      <img class="viptwo" src="../../assets/img/21.png" alt="" />
      <div class="xieyi">
        <van-checkbox v-model="checked" checked-color="#282725" class="check"
          >同意</van-checkbox
        >
        <span @click="goxieyi">《企乐缘会员服务协议》</span>
      </div>
      <!-- 《企乐缘会员服务协议》 -->
      <div class="btn" @click="openvip">
        ￥{{ info.vip_price }}<span class="small">/月</span> 立即开通
      </div>
    </van-popup>
  </div>
</template>

<script>
import { buy_vip, payment } from "@/api/vip";
import { order_count } from "@/api/activity";
import { myInfo, my_matchmaker } from "@/api/my";
export default {
  data() {
    return {
      info: {},
      myMatchmaker: {},
      joinActivity: {},
      yuyuebadge: "",
      joinbadge: "",
      usedbadge: "",
      shoubadge: "",
      vipShow: false,
      checked: false
    };
  },
  created() {},
  mounted() {
    this.getInfo();
    this.getmyMaker();
    this.getOrderCount();
  },
  methods: {
    /* 开通vip */
    async openvip() {
      if (!this.checked) {
        return this.$toast("请详细阅读并同意企乐缘服务协议");
      }
      const res = await buy_vip();
      let { total, sNo } = res.data.data;
      console.log(total, sNo, 654);
      if (res.data.code == 200) {
        const res2 = await payment({ sNo, total });
        let obj = {
          appId: res2.data.appid,
          nonceStr: res2.data.nonceStr,
          out_trade_no: res2.data.out_trade_no,
          package: res2.data.package,
          paySign: res2.data.paySign,
          signType: res2.data.signType,
          timeStamp: res2.data.timeStamp
        }; /*   state: res2.data.state, */
        this.onBridgeReady(obj);
      }
    },
    onBridgeReady(par) {
      console.log(par, "支付参数");
      // eslint-disable-next-line no-undef
      WeixinJSBridge.invoke("getBrandWCPayRequest", par, res => {
        console.log(res, "支付回调");
        if (res.err_msg == "get_brand_wcpay_request:ok") {
          // 使用以上方式判断前端返回,微信团队郑重提示：
          //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
          this.vipShow = false;
          this.getInfo();
          console.log("支付成功");
        }
        if (typeof WeixinJSBridge == "undefined") {
          if (document.addEventListener) {
            document.addEventListener(
              "WeixinJSBridgeReady",
              // eslint-disable-next-line no-undef
              onBridgeReady,
              false
            );
          } else if (document.attachEvent) {
            // eslint-disable-next-line no-undef
            document.attachEvent("WeixinJSBridgeReady", onBridgeReady);
            // eslint-disable-next-line no-undef
            document.attachEvent("onWeixinJSBridgeReady", onBridgeReady);
          }
        } else {
          // eslint-disable-next-line no-undef
          onBridgeReady();
        }
      });
    },
    async getInfo() {
      try {
        const res = await myInfo();
        this.info = res.data.data.list;
      } catch (err) {
        console.log(err);
      }
    },
    async getmyMaker() {
      try {
        const res = await my_matchmaker();
        if (res.data.code == 200) {
          this.myMatchmaker = res.data.data;
          console.log(res.data.data, "jk");
        }
        if (res.data.code == 4004) {
          this.myMatchmaker = {};
        } else {
          console.log(11);
        }
        console.log(res);
      } catch (err) {
        console.log(err);
      }
    },
    addMatchmaker() {
      this.$router.push("/matchmaker");
    },
    allActiity() {
      this.$router.push("/myActivity");
    },
    async getOrderCount() {
      const res = await order_count();
      if (res.data.code == 200) {
        console.log(res, 221654);
        this.joinActivity = res.data.data.joinActivity;
        /* this.yuyuebadge =  */ res.data.data.orderCount.forEach(item => {
          switch (item.status) {
            case 1:
              this.joinbadge = item.num;
              break;
            case 2:
              this.usedbadge = item.num;
              break;
            case 3:
              this.yuyuebadge = item.num;
              break;
            case 4:
              this.yuyuebadge = item.num;
              break;
            default:
              break;
          }
        });
      }
    },

    goactive(index) {
      this.$router.push({
        path: "/myActivity",
        query: {
          active: index
        }
      });
    },
    /* 查看协议 */
    goxieyi() {
      this.$router.push({
        path: "/xieyi",
        query: {
          type: "1"
        }
      });
    }
  },
  destroyed() {
    this.checked = false;
  }
};
</script>

<style lang="scss" scoped>
/deep/.van-info {
  position: absolute;
  top: 3px;
  right: 3px;
}
#mine {
  min-height: 100vh;
  background: url("./../../assets/img/05.png") 0 0 no-repeat;
  background-size: 100%;
  background-color: #f5f6fa;
  .info {
    display: flex;
    padding: 0 20px;
    padding-top: 150px;
    &-left {
      flex: 1;
      margin-right: 20px;
      display: flex;
      min-width: 0;
      flex-direction: column;
      justify-content: center;
      .name {
        font-size: 46px;
        font-weight: 500;
        color: #000000;
        font-weight: 600;
      }
      .vip {
        width: 165px;
        height: 48px;
      }
    }
    &-right {
      .avatar {
        width: 128px;
        height: 128px;
        border-radius: 50%;
        flex-shrink: 1;
        border: 4px solid #fff;
      }
      .logo {
        width: 31px;
        height: 31px;
        // background: #009fff;
        border: 2px solid #ffffff;
        border-radius: 50%;
        margin-left: -30px;
      }
    }
  }
  .vip-info {
    width: 694px;
    height: 112px;
    background: url("./../../assets/img/03.png") 0 0 no-repeat;
    background-size: 100%;
    margin: auto;
    margin-top: 47px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 26px;
    box-sizing: border-box;
    .icon-vip {
      width: 36px;
      height: 36px;
      background: url("./../../assets/img/04.png") 0 0 no-repeat;
      background-size: 100%;
      display: block;
      margin-right: 10px;
    }
    .center {
      flex: 1;
      margin: 0 20px;
      font-size: 28px;
      font-weight: 600;
      color: #895423;
    }
    .renewal-btn {
      display: inline-block;
      padding: 0 24px;
      height: 49px;
      // max-width: 131px;
      background: #512c19;
      border-radius: 24px;
      border: 0;
      color: #fff;
      text-align: center;
      //padding: 0 33px;
    }
    .notice-swipe {
      height: 45px;
      line-height: 40px;
    }
    /deep/.van-notice-bar {
      background-color: unset;
      width: 480px;
      padding: 0;
    }
  }
  .activity {
    // width: 694px;
    background: #ffffff;
    border-radius: 28px;
    padding: 0 22px 42px;
    margin: 0 28px;
    margin-top: 28px;
    /deep/.van-cell {
      padding-left: 0;
      padding-right: 0;
    }
    .title {
      font-size: 33px;
      font-weight: 500;
      color: #000000;
    }
    .icon-img {
      width: 104px;
      height: 104px;
    }

    .apply {
      //  margin-top: 27px;
      display: flex;
      background: #f6f8fb;
      border-radius: 14px;
      .active {
        width: 109px;
        height: 109px;
        border-radius: 14px 0px 0px 14px;
        margin-right: 24px;
        .actimg {
          width: 109px;
          height: 109px;
          border-radius: 14px 0px 0px 14px;
        }
      }
      .axtname {
        display: flex;
        flex-direction: column;
        padding: 21px 0;
        div {
          color: #999999;
        }
        .txt {
          margin-bottom: 18px;
          color: #333333;
          max-width: 478px;
        }
      }
    }
  }
  .matchmaker {
    background: #ffffff;
    border-radius: 28px;
    padding: 0 22px 25px;
    margin: 0 28px;
    margin-top: 28px;
    /deep/.van-cell {
      padding-left: 0;
      padding-right: 0;
    }
    .vx {
      width: 243px;
      height: 243px;
      display: flex;
      justify-content: center;
      margin: 0 auto;
    }
    .add {
      text-align: center;
      font-size: 25px;
      color: #333333;
      margin-top: 20px;
    }
    .title {
      font-size: 33px;
      font-weight: 600;
      color: #000000;
    }
    .empty {
      display: flex;
      flex-direction: column;
      align-items: center;
      .add {
        width: 253px;
        height: 76px;
        background: #fff;
        border-radius: 38px;

        line-height: 76px;
        color: #333333;
        font-weight: 600;
        margin-bottom: 37px;
        display: flex;
        align-items: center;
        font-size: 28px;
        justify-content: center;
        img {
          width: 30px;
          height: 30px;
          margin-right: 10px;
        }
      }
      .tip {
        color: #999999;
        font-size: 25px;
      }
    }
    .apply {
      //  margin-top: 27px;
      display: flex;
      background: #fff;
      border-radius: 14px;
      .active {
        width: 109px;
        height: 109px;
        border-radius: 14px 0px 0px 14px;
        margin-right: 24px;
        .actimg {
          width: 109px;
          height: 109px;
          border-radius: 14px 0px 0px 14px;
        }
      }
      .axtname {
        display: flex;
        flex-direction: column;
        padding: 21px 0;
        div {
          color: #999999;
        }
        .txt {
          margin-bottom: 18px;
          color: #333333;
        }
      }
    }
  }
  .zhanwei {
    width: 1px;
    height: 150px;
  }
}
.popup {
  .title {
    font-size: 35px;
    margin: 43px 0 0 36px;
    font-weight: bold;
    color: #000000;
    margin-bottom: 52px;
  }
  .first {
    font-size: 32px;
    text-align: center;
    font-weight: 400;
    color: #999999;
    line-height: 56px;
  }
  .second {
    text-align: center;
    font-weight: 400;
    color: #999999;
    font-size: 32px;
    line-height: 56px;
    margin-bottom: 38px;
    .pink {
      color: #ff5697;
    }
  }
  .openvip {
    width: 694px;
    height: 395px;
    margin: 0 28px;
  }
  .viptwo {
    width: 694px;
    height: 356px;
    margin: 13px 28px;
  }
  .xieyi {
    display: flex;
    align-items: center;
    margin: 60px 0 145px 63px;
  }
  .check {
    //
    /deep/.van-checkbox__label {
      color: #999999;
    }
  }

  .btn {
    position: fixed;
    bottom: 30px;
    width: 625px;
    height: 97px;
    background: linear-gradient(-30deg, #282725 0%, #282725 98%);
    border-radius: 45px;
    font-size: 35px;
    color: #ffdfa9;
    text-align: center;
    line-height: 97px;
    margin: 0 63px;
    .small {
      font-size: 23px;
    }
  }
}
</style>
